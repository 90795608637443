
import { Component } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AuthModule from "@/store/auth";
import CalendarModule from "@/store/calendar";
import ReservationModule from "@/store/reservation";
import HospitalModule from "@/store/hospital";
import MemberService from "@/services/members";
import FacilityService from "@/services/facilities";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import moment from "moment";
import { asyncMap, authCheck } from "@/utils/Util";
import { CourseData, CourseDay, ReservationData } from "@/@types/reservation";
import { BusinessHours } from "@/@types/event";
import Cognito from "@/plugins/cognito";

@Component({
  name: "MyPage",
  components: {
    Header,
    Footer,
  },
})
export default class MyPage extends CheckCommonLogin {
  private get me() {
    return AuthModule.me;
  }

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private loading = false;

  private editLoading = false;

  private deleteLoading = false;

  private unsubscribeLoading = false;

  private deleteId = "";

  private currentReservations: ReservationData[] = [];

  private oldReservations: ReservationData[] = [];

  private reservations: ReservationData[] = [];

  private currentReservationCourse: CourseData | null = null;

  // reservationsから

  private async editReservation(reservation: ReservationData) {
    if (this.me) {
      const isMember = await authCheck(this.me.id);
      if (!isMember) {
        Cognito.signOut();
        localStorage.removeItem("vuex");
        this.$router.push({ name: "Login" });
        return;
      }
    }
    if (!reservation.courseVisible) {
      this.showModal("specialNoChange");
      return;
    }
    if (reservation.status === "immutable") {
      this.showModal("error");
      return;
    }
    if (reservation.data_type === "reservation_special") {
      this.showModal("specialNoChange");
      return;
    }
    if (!this.isChangeable(reservation)) {
      this.showModal("noChange");
      return;
    }
    this.editLoading = true;
    const res = await FacilityService.getCourseDetail(reservation.facility_id, reservation.course_id);
    this.currentReservationCourse = res.data.data[0];
    HospitalModule.changeHospitalId(reservation.facility_id);
    ReservationModule.changeSelectedCourse(res.data.data[0]);
    await this.businessHoursArrayFactory();
    this.$router.push({ name: "MyPageReservationEdit", params: { id: reservation.id } });
  }

  private async deleteModalOpen(reservation: ReservationData) {
    if (this.me) {
      const isMember = await authCheck(this.me.id);
      if (!isMember) {
        Cognito.signOut();
        localStorage.removeItem("vuex");
        this.$router.push({ name: "Login" });
        return;
      }
    } else {
      localStorage.removeItem("vuex");
      this.$router.push({ name: "Login" });
      return;
    }
    if (!reservation.courseVisible) {
      this.showModal("specialNoDelete");
      return;
    }
    if (reservation.status === "immutable") {
      this.showModal("error");
      return;
    }
    if (reservation.data_type === "reservation_special") {
      this.showModal("specialNoDelete");
      return;
    }
    if (!this.isChangeable(reservation)) {
      this.showModal("noDelete");
      return;
    }
    this.deleteId = reservation.id;
    this.showModal("delete");
  }

  private withdrawnLoading = false;
  private async withdrawn() {
    try {
      this.withdrawnLoading = true;
      if (this.me) {
        await MemberService.update(this.me?.id, { member_status: "non_member" });
        await MemberService.delete(this.me?.id);
      }
      this.$router.push({ name: "Withdrawn" });
      AuthModule.changeMe(null);
      this.withdrawnLoading = false;
    } catch (error: any) {
      this.withdrawnLoading = false;
      throw new Error(error);
    }
  }

  private async subscribe() {
    try {
      this.unsubscribeLoading = true;
      if (this.me) {
        await MemberService.update(this.me?.id, { optin_consented_at: moment().format() });
        const res = await MemberService.get(this.me?.id);
        AuthModule.changeMe(res.data.data[0]);
        this.unsubscribeLoading = false;
      }
    } catch (error: any) {
      this.unsubscribeLoading = false;
      throw new Error(error);
    }
  }

  private async reservationDelete() {
    try {
      this.deleteLoading = true;
      if (this.me) await MemberService.deleteReservation(this.me.id, this.deleteId);
      this.closeModal("delete");
      this.getReservations();
      this.deleteLoading = false;
    } catch (error: any) {
      this.deleteLoading = false;
      throw new Error(error);
    }
  }

  private async toCourseSelect(reservation: ReservationData) {
    if (reservation.animalId) {
      ReservationModule.changeReReservationPetIds([reservation.animalId]);
    }
    this.$router.push({ name: "CourseSelect", query: { id: reservation.facility_id } });
  }

  private signOut() {
    Cognito.signOut();
    localStorage.removeItem("vuex");
    this.$router.push({ name: "Login" });
  }

  private toChangePassword() {
    this.$router.push({ name: "ChangePassword" });
  }

  private async mounted() {
    this.loading = true;
    if (this.me == null) {
      this.$router.push({ name: "Login" });
    } else {
      await this.getReservations();
    }
    this.loading = false;
  }
  private movePetEdit(id: string) {
    this.$router.push({ name: "MyPagePetEdit", params: { id: id } });
  }

  private reservationDateFactory(time: string) {
    return moment(new Date(time).toISOString()).format("YYYY/MM/DD(ddd)");
  }
  private reservationTimeFactory(time: string) {
    return moment(new Date(time).toISOString()).format("HH:mm");
  }

  private convertDays(days: string[]) {
    const convertedDays = days.map((value) => {
      if (value === "Sun") {
        return 0;
      } else if (value === "Mon") {
        return 1;
      } else if (value === "Tue") {
        return 2;
      } else if (value === "Wed") {
        return 3;
      } else if (value === "Thu") {
        return 4;
      } else if (value === "Fri") {
        return 5;
      } else if (value === "Sat") {
        return 6;
      } else {
        return 99;
      }
    });
    return convertedDays;
  }

  private businessHourStringFactory(hour: number, minute: number) {
    const hours = moment().set("hour", hour).set("minute", minute).format("HH:mm");
    return hours;
  }

  private businessHoursArrayFactory() {
    const businessHoursArray: BusinessHours[] = [];
    const days = this.currentReservationCourse?.details[0].days;

    days?.forEach((courseDay: CourseDay) => {
      // 曜日のリストをstring→numberへ
      const daysOfWeek = this.convertDays(courseDay.available_days);
      // 終日休診の場合
      if (!courseDay.is_no_medical_all_day) {
        courseDay.times.forEach((time) => {
          const startTime = this.businessHourStringFactory(time.on_time.start_hour, time.on_time.start_minute);
          const endTime = this.businessHourStringFactory(time.on_time.end_hour, time.on_time.end_minute);
          businessHoursArray.push({
            daysOfWeek,
            startTime,
            endTime,
          });
        });
      }
    });
    CalendarModule.changeBusinessHours(businessHoursArray);
  }

  private async getCurrentReservations() {
    const now = new Date().toISOString();
    this.currentReservations = this.reservations.filter((reservation: ReservationData) =>
      moment(new Date(reservation.datetime).toISOString()).isAfter(moment(now))
    );
    this.currentReservations.sort(function (x, y) {
      var firstDate = new Date(x.datetime),
        SecondDate = new Date(y.datetime);

      if (firstDate < SecondDate) return -1;
      if (firstDate > SecondDate) return 1;
      return 0;
    });
  }
  private async getOldReservations() {
    const now = new Date().toISOString();
    this.oldReservations = this.reservations.filter((reservation: ReservationData) =>
      moment(new Date(reservation.datetime).toISOString()).isBefore(moment(now))
    );
  }

  private isChangeable(reservation: any) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let isChangeable = false;
    if (reservation.courseDetail) {
      const reservationDate = moment(new Date(reservation.datetime).toISOString());
      const day = days[reservationDate.get("day")];
      const courseDaysData = reservation.courseDetail ? reservation.courseDetail.days : [];
      const timeObject = courseDaysData.find((value: any) => value.available_days.includes(day));
      if (timeObject) {
        const endMonth = -timeObject.times[0].reception_time.end_month;
        const endDate = -timeObject.times[0].reception_time.end_date;
        const endHour = timeObject.times[0].reception_time.end_hour;
        // item.datetimeの月からendMonthを引いて、endDateも引いて時間はendHourをセットする
        const endReceptionTime = moment(new Date(reservation.datetime))
          .add(endMonth, "month")
          .add(endDate, "day")
          .set("hour", endHour)
          .format("YYYY-MM-DD HH:mm");
        // 現在時刻が受付時間を超えているかどうか
        isChangeable = moment(new Date()).isBefore(moment(endReceptionTime));
      }
      //変更可能かどうか
    }
    return isChangeable;
  }

  private async getReservations() {
    try {
      if (this.me) {
        const reservationRes = await MemberService.getReservationsByMemberId(this.me.id);
        this.reservations = await asyncMap(reservationRes.data.data, async (item: ReservationData) => {
          const hospitalRes = await FacilityService.getHospital(item.facility_id);
          const getCourseDetail = await FacilityService.getHospitalCourseDetail(item.facility_id, item.course_id);
          let petName = "";
          let animalId = "";
          if (item.pet_id) {
            const res = await MemberService.getPet(item.member_id, item.pet_id);
            if (res.data.data.length && res.data.data[0].is_enabled) {
              petName = res.data.data[0].name;
              animalId = res.data.data[0].anirece_animal_id;
            } else {
              petName = "削除されたどうぶつです";
            }
          }

          return {
            ...item,
            hospitalName: hospitalRes.data.data[0].name,
            courseName: getCourseDetail.data.data[0] ? getCourseDetail.data.data[0].name : "削除されたコースです",
            courseDetail: getCourseDetail.data.data[0] ? getCourseDetail.data.data[0].details[0] : {},
            courseVisible: getCourseDetail.data.data[0] ? getCourseDetail.data.data[0].visible : false,
            petName,
            animalId,
          };
        });
        // 降順にソート
        this.reservations.sort(function (x, y) {
          var firstDate = new Date(x.datetime),
            SecondDate = new Date(y.datetime);

          if (firstDate < SecondDate) return 1;
          if (firstDate > SecondDate) return -1;
          return 0;
        });
        this.getCurrentReservations();
        this.getOldReservations();
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
