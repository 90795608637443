import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { WeeklyEvent, MonthlyEvent, DayEvent, BusinessHours } from "@/@types/event";
import moment from "moment";

interface ICalendarModule {
  _currentEvents: WeeklyEvent[];
  _weeklyEvents: WeeklyEvent[];
  _selectedTargetTime: Date | null;
  _businessHours: BusinessHours[];
}

@Module({
  dynamic: true,
  store,
  name: "calendar",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).calendar,
})
class CalendarModule extends VuexModule implements ICalendarModule {
  public _currentEvents: WeeklyEvent[] = [];
  public _weeklyEvents: WeeklyEvent[] = [];
  public _selectedTargetTime: Date | null = null;
  public _isEditDailyEvent = false;
  public _businessHours: BusinessHours[] = [];
  public _currentDate = "";

  /**
   * Weekly変更
   * @param weeklyEvent
   */
  @Mutation
  public weeklyAdd(weeklyEvent: WeeklyEvent[]) {
    this._weeklyEvents = weeklyEvent;
  }

  /**
   * 変更
   * @param name
   */
  @Mutation
  public changeCurrent(name: string) {
    if (name === "weekly") {
      this._currentEvents = this._weeklyEvents;
    }
  }

  /**
   * 選択中の予約セット
   * @param time
   */
  @Mutation
  public selectedDailyEvent(time: Date) {
    this._selectedTargetTime = time;
  }

  /**
   * 選択日付変更
   * @param date
   */
  @Mutation
  public changeCurrentDate(date: string) {
    this._currentDate = date;
  }

  /**
   * BusinessHours追加
   * @param businessHours
   */
  @Mutation
  public changeBusinessHours(businessHours: BusinessHours[]) {
    this._businessHours = businessHours;
  }

  public get currentEvents() {
    return this._currentEvents || [];
  }
  public get weeklyEvents() {
    return this._weeklyEvents || [];
  }
  public get isEditDailyEvent() {
    return this._isEditDailyEvent || false;
  }
  public get selectedTargetTime() {
    return this._selectedTargetTime || "";
  }
  public get businessHours() {
    return this._businessHours || [];
  }
  public get currentDate() {
    return this._currentDate;
  }
}
export default getModule(CalendarModule);
